.summary-title, .summary-title p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 56px;
    line-height: 48px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 36px;
}

.summary-details-container {
    padding-bottom: 36px;
    border-bottom: 1px solid var(--Gray200);
}

.summary-details-container .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width:20%;
}

.summary-details-container .col p{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.summary-detail-bottom {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Gray500);
    margin-bottom: 0;
}

.summary-detail-top {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0;
}

.status-icon-container {
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.status-icon-online {
    background-color: var(--Green300);
}

.status-icon-offline {
    background-color: var(--Gray300);
}

.summary-detail-top:hover, .summary-detail-bottom:hover {
    cursor: help;
}
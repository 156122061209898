/* Blue */

.btn-blue {
    border-radius: 100px;
    padding: 10px 16px;
    background-color: var(--Blue400);
    color: var(--White100);
    border: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    transition: 0.3s all ease-in-out;
}

.btn-blue:hover {
    background-color: var(--Blue350);
}

.btn-blue:focus {
    background-color: var(--Blue350);
    box-shadow: 0 0 0 4px rgba(0, 99, 231, 0.3);
}

/* Gray */
.btn-gray {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 24px;
    background-color: var(--Gray200);
    border-radius: 100px;
    border: none;
    transition: 0.3s all ease-in-out;
}

.btn-gray:hover {
    background-color: var(--Gray250);
}

.btn-gray:focus {
    background-color: var(--Gray250);
    box-shadow: 0 0 0 4px rgba(219, 219, 230, 0.4);
}

/* White */

.btn-white {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 24px;
    background-color: var(--White100);
    border-radius: 100px;
    border: 1px solid var(--Gray300);
    transition: 0.3s all ease-in-out;    
    color: var(--Gray800);
}

.btn-white:hover {
    background-color: var(--CustomWhite200);    
    color: var(--Gray800);
    border: 1px solid var(--Gray400);
}

.btn-white:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
}

/* Red */

.btn-red {
    border-radius: 100px;
    padding: 10px 16px;
    background-color: var(--Red300);
    color: var(--White100);
    border: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    transition: 0.3s all ease-in-out;
}

.btn-red:hover {
    background-color: var(--Red350);
}

.btn-red:focus {
    background-color: var(--Red350);
    box-shadow: 0 0 0 4px rgba(222, 16, 27, 0.15);
}

/* Close - Gray */

.btn-gray-close {
    padding: 8px;
    border-radius: 100px;
    background-color: var(--Gray200);
    border: none;
    line-height: 0.85;
    height: 32px;
    width: 32px;
    transition: 0.3s all ease-in-out;
}

.btn-gray-close:hover {
    background-color: var(--Gray250);
}

.btn-gray-close:focus {
    background-color: var(--Gray250);
    box-shadow: 0 0 0 4px rgba(219, 219, 230, 0.4);
}

/* Plain - Red */

.btn-plain-red {
    background-color: transparent;
    border: none;
    color: var(--Red300);
    border-radius: 100px;
    padding: 5px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent;
}

.btn-plain-red:hover {
    border: 1px solid var(--Red300);
    color: var(--Red300);
}

.btn-plain-red:focus {
    border: 1px solid var(--Red300);
    color: var(--Red300);
    box-shadow: 0 0 0 4px rgba(222, 16, 27, 0.15);
}
.account-info-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-info-modal .modal-content {
    width: 391px;
    max-width: 391px;
    background: #fff;
    border-radius: 24px;
    margin-top: 117px;
}

.account-info-modal .modal-body {
    padding: 24px;
}

.account-info-modal .modal-footer {
    padding: 12px;
    border-top: 1px solid var(--Gray200);
    background-color: var(--Gray100);
    border-radius: 0 0 24px 24px;
}

.account-info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.account-info-item-label {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    font-weight: 400;
    color: var(--Gray600);
}

.account-info-item-value {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    font-weight: 400;
    color: var(--Black100);
}
.rename-module-modal .modal-dialog {
    max-width: 391px;
}

.rename-module-modal .modal-dialog .modal-content {
    border-radius: 24px;
}

.rename-module-modal .modal-body {
    padding: 24px;
}

.rename-module-modal .title-modal {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.rename-module-modal .socket-enable-container {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--Gray200);
}
.module-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.title-icon-container {
    padding: 4px 8px 6px;
    background-color: var(--Gray200);
    border-radius: 50%;
    border: none;
}

.title-icon-container:hover {
    cursor: pointer;
}

.module-title .dropdown-menu {
    padding: 4px;
    gap: 4px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 3px 8px 0px #A9A9C64D;
    box-shadow: 0px 1px 1px 0px #0000000D;
    box-shadow: 0px 1px 20px 0px #0000000D;
}

.module-title .dropdown-menu button {
    border-radius: 8px;
    padding: 6px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--Black100)
}

.module-title .dropdown-menu button:active {
    color: var(--White100);
}

.module-title .dropdown-menu li:not(:last-child) {
    margin-bottom: 4px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: var(--Blue400);
}

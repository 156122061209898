.downloads-title {
    margin-top: 24px;
}

.manual-container {
    padding: 16px 24px 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--Gray200);
    border-radius: 8px;
    align-items: center;
    text-decoration: none;
}

.download-title-size-container {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.manual-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;   
}

.pdf-icon-container {
    padding: 8px;
    background-color: var(--White100);
    border-radius: 50%;
}

.download-title-size-container span {
    text-decoration: none;
}

.download-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--Black100);
}

.download-size {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Black100);
}

.download-container {
    border-top: 1px solid var(--Gray200);
}
.global-notification {
    background-color: var(--Gray700);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 343px;
    min-height: 50px;
    color: var(--White100);
    padding: 14px;
}

.notification-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.notification-close-container {
    line-height: 10px;
    font-size: 14px;
}

.notification-close-container:hover {
    cursor: pointer;
}

.global-notification p {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
}

.notification-container-outer {
    position: absolute;
    top: 52px;
    right: 0;
    width: 100%;
}

.notification-context {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.notification-context.show {
    opacity: 1;
}
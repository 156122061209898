@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url(../src/assets/fonts/Inter.ttf) format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: url(../src/assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url(../src/assets/fonts/Inter.ttf) format("truetype");
}

body {
    font-family: "Inter", sans-serif;
    background-color: var(--Gray100);
    color: var(--Black200);
    letter-spacing: -0.02em;
}

.module-panel {
    border-radius: 16px;
    border: 1px solid var(--Gray300);
    padding: 24px;
}

.start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.center-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.evenly-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.between-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.form-check-input:focus {
    box-shadow: none;
}

.modal .modal-dialog {
    margin-top: 118px;
}

.history-past-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--Black100);
}

.module-title-small-text {
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    align-self: flex-end;
    margin-left: 8px;
    margin-right: 8px;
}

.dropdown-gray {
    background-color: var(--Gray200);
    padding: 4px 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.dot-8 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.dot-blue {
    background-color: var(--Blue400);
}

.text-input .MuiInputBase-root{
    border-radius: 8px;
}
/* Gap */
.g-0 {
    gap: 0;
}

.g-1 {
    gap: 1px;
}

.g-2 {
    gap: 2px;
}

.g-3 {
    gap: 3px;
}

.g-4 {
    gap: 4px;
}

.g-5 {
    gap: 5px;
}

.g-6 {
    gap: 6px;
}

.g-7 {
    gap: 7px;
}

.g-8 {
    gap: 8px;
}

.g-9 {
    gap: 9px;
}

.g-10 {
    gap: 10px;
}

.g-11 {
    gap: 11px;
}

.g-12 {
    gap: 12px;
}

.g-13 {
    gap: 13px;
}

.g-14 {
    gap: 14px;
}

.g-15 {
    gap: 15px;
}

.g-16 {
    gap: 16px;
}

.g-17 {
    gap: 17px;
}

.g-18 {
    gap: 18px;
}

.g-19 {
    gap: 19px;
}

.g-20 {
    gap: 20px;
}

.g-21 {
    gap: 21px;
}

.g-22 {
    gap: 22px;
}

.g-23 {
    gap: 23px;
}

.g-24 {
    gap: 24px;
}

.g-25 {
    gap: 25px;
}

.g-26 {
    gap: 26px;
}

.g-27 {
    gap: 27px;
}

.g-28 {
    gap: 28px;
}

.g-29 {
    gap: 29px;
}

.g-30 {
    gap: 30px;
}

.g-31 {
    gap: 31px;
}

.g-32 {
    gap: 32px;
}

.g-33 {
    gap: 33px;
}

.g-34 {
    gap: 34px;
}

.g-35 {
    gap: 35px;
}

.g-36 {
    gap: 36px;
}

/* Padding */
.pd-0 {
    padding: 0;
}

.pd-1 {
    padding: 1px;
}

.pd-2 {
    padding: 2px;
}

.pd-3 {
    padding: 3px;
}

.pd-4 {
    padding: 4px;
}

.pd-5 {
    padding: 5px;
}

.pd-6 {
    padding: 6px;
}

.pd-7 {
    padding: 7px;
}

.pd-8 {
    padding: 8px;
}

.pd-9 {
    padding: 9px;
}

.pd-10 {
    padding: 10px;
}

.pd-11 {
    padding: 11px;
}

.pd-12 {
    padding: 12px;
}

.pd-13 {
    padding: 13px;
}

.pd-14 {
    padding: 14px;
}

.pd-15 {
    padding: 15px;
}

.pd-16 {
    padding: 16px;
}

.pd-17 {
    padding: 17px;
}

.pd-18 {
    padding: 18px;
}

.pd-19 {
    padding: 19px;
}

.pd-20 {
    padding: 20px;
}

.pd-21 {
    padding: 21px;
}

.pd-22 {
    padding: 22px;
}

.pd-23 {
    padding: 23px;
}

.pd-24 {
    padding: 24px;
}

.pd-25 {
    padding: 25px;
}

.pd-26 {
    padding: 26px;
}

.pd-27 {
    padding: 27px;
}

.pd-28 {
    padding: 28px;
}

.pd-29 {
    padding: 29px;
}

.pd-30 {
    padding: 30px;
}

.pd-31 {
    padding: 31px;
}

.pd-32 {
    padding: 32px;
}

.pd-33 {
    padding: 33px;
}

.pd-34 {
    padding: 34px;
}

.pd-35 {
    padding: 35px;
}

.pd-36 {
    padding: 36px;
}

/* Padding Left */
.pd-l-0 {
    padding-left: 0;
}

.pd-l-1 {
    padding-left: 1px;
}

.pd-l-2 {
    padding-left: 2px;
}

.pd-l-3 {
    padding-left: 3px;
}

.pd-l-4 {
    padding-left: 4px;
}

.pd-l-5 {
    padding-left: 5px;
}

.pd-l-6 {
    padding-left: 6px;
}

.pd-l-7 {
    padding-left: 7px;
}

.pd-l-8 {
    padding-left: 8px;
}

.pd-l-9 {
    padding-left: 9px;
}

.pd-l-10 {
    padding-left: 10px;
}

.pd-l-11 {
    padding-left: 11px;
}

.pd-l-12 {
    padding-left: 12px;
}

.pd-l-13 {
    padding-left: 13px;
}

.pd-l-14 {
    padding-left: 14px;
}

.pd-l-15 {
    padding-left: 15px;
}

.pd-l-16 {
    padding-left: 16px;
}

.pd-l-17 {
    padding-left: 17px;
}

.pd-l-18 {
    padding-left: 18px;
}

.pd-l-19 {
    padding-left: 19px;
}

.pd-l-20 {
    padding-left: 20px;
}

.pd-l-21 {
    padding-left: 21px;
}

.pd-l-22 {
    padding-left: 22px;
}

.pd-l-23 {
    padding-left: 23px;
}

.pd-l-24 {
    padding-left: 24px;
}

.pd-l-25 {
    padding-left: 25px;
}

.pd-l-26 {
    padding-left: 26px;
}

.pd-l-27 {
    padding-left: 27px;
}

.pd-l-28 {
    padding-left: 28px;
}

.pd-l-29 {
    padding-left: 29px;
}

.pd-l-30 {
    padding-left: 30px;
}

.pd-l-31 {
    padding-left: 31px;
}

.pd-l-32 {
    padding-left: 32px;
}

.pd-l-33 {
    padding-left: 33px;
}

.pd-l-34 {
    padding-left: 34px;
}

.pd-l-35 {
    padding-left: 35px;
}

.pd-l-36 {
    padding-left: 36px;
}

/* Padding Right */
.pd-r-0 {
    padding-right: 0;
}

.pd-r-1 {
    padding-right: 1px;
}

.pd-r-2 {
    padding-right: 2px;
}

.pd-r-3 {
    padding-right: 3px;
}

.pd-r-4 {
    padding-right: 4px;
}

.pd-r-5 {
    padding-right: 5px;
}

.pd-r-6 {
    padding-right: 6px;
}

.pd-r-7 {
    padding-right: 7px;
}

.pd-r-8 {
    padding-right: 8px;
}

.pd-r-9 {
    padding-right: 9px;
}

.pd-r-10 {
    padding-right: 10px;
}

.pd-r-11 {
    padding-right: 11px;
}

.pd-r-12 {
    padding-right: 12px;
}

.pd-r-13 {
    padding-right: 13px;
}

.pd-r-14 {
    padding-right: 14px;
}

.pd-r-15 {
    padding-right: 15px;
}

.pd-r-16 {
    padding-right: 16px;
}

.pd-r-17 {
    padding-right: 17px;
}

.pd-r-18 {
    padding-right: 18px;
}

.pd-r-19 {
    padding-right: 19px;
}

.pd-r-20 {
    padding-right: 20px;
}

.pd-r-21 {
    padding-right: 21px;
}

.pd-r-22 {
    padding-right: 22px;
}

.pd-r-23 {
    padding-right: 23px;
}

.pd-r-24 {
    padding-right: 24px;
}

.pd-r-25 {
    padding-right: 25px;
}

.pd-r-26 {
    padding-right: 26px;
}

.pd-r-27 {
    padding-right: 27px;
}

.pd-r-28 {
    padding-right: 28px;
}

.pd-r-29 {
    padding-right: 29px;
}

.pd-r-30 {
    padding-right: 30px;
}

.pd-r-31 {
    padding-right: 31px;
}

.pd-r-32 {
    padding-right: 32px;
}

.pd-r-33 {
    padding-right: 33px;
}

.pd-r-34 {
    padding-right: 34px;
}

.pd-r-35 {
    padding-right: 35px;
}

.pd-r-36 {
    padding-right: 36px;
}

/* Padding Top */
.pd-t-0 {
    padding-top: 0;
}

.pd-t-1 {
    padding-top: 1px;
}

.pd-t-2 {
    padding-top: 2px;
}

.pd-t-3 {
    padding-top: 3px;
}

.pd-t-4 {
    padding-top: 4px;
}

.pd-t-5 {
    padding-top: 5px;
}

.pd-t-6 {
    padding-top: 6px;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-t-8 {
    padding-top: 8px;
}

.pd-t-9 {
    padding-top: 9px;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-t-11 {
    padding-top: 11px;
}

.pd-t-12 {
    padding-top: 12px;
}

.pd-t-13 {
    padding-top: 13px;
}

.pd-t-14 {
    padding-top: 14px;
}

.pd-t-15 {
    padding-top: 15px;
}

.pd-t-16 {
    padding-top: 16px;
}

.pd-t-17 {
    padding-top: 17px;
}

.pd-t-18 {
    padding-top: 18px;
}

.pd-t-19 {
    padding-top: 19px;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-t-21 {
    padding-top: 21px;
}

.pd-t-22 {
    padding-top: 22px;
}

.pd-t-23 {
    padding-top: 23px;
}

.pd-t-24 {
    padding-top: 24px;
}

.pd-t-25 {
    padding-top: 25px;
}

.pd-t-26 {
    padding-top: 26px;
}

.pd-t-27 {
    padding-top: 27px;
}

.pd-t-28 {
    padding-top: 28px;
}

.pd-t-29 {
    padding-top: 29px;
}

.pd-t-30 {
    padding-top: 30px;
}

.pd-t-31 {
    padding-top: 31px;
}

.pd-t-32 {
    padding-top: 32px;
}

.pd-t-33 {
    padding-top: 33px;
}

.pd-t-34 {
    padding-top: 34px;
}

.pd-t-35 {
    padding-top: 35px;
}

.pd-t-36 {
    padding-top: 36px;
}

/* Padding Bottom */
.pb-b-0 {
    padding-bottom: 0;
}

.pd-b-1 {
    padding-bottom: 1px;
}

.pd-b-2 {
    padding-bottom: 2px;
}

.pd-b-3 {
    padding-bottom: 3px;
}

.pd-b-4 {
    padding-bottom: 4px;
}

.pd-b-5 {
    padding-bottom: 5px;
}

.pd-b-6 {
    padding-bottom: 6px;
}

.pd-b-7 {
    padding-bottom: 7px;
}

.pd-b-8 {
    padding-bottom: 8px;
}

.pd-b-9 {
    padding-bottom: 9px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.pd-b-11 {
    padding-bottom: 11px;
}

.pd-b-12 {
    padding-bottom: 12px;
}

.pd-b-13 {
    padding-bottom: 13px;
}

.pd-b-14 {
    padding-bottom: 14px;
}

.pd-b-15 {
    padding-bottom: 15px;
}

.pd-b-16 {
    padding-bottom: 16px;
}

.pd-b-17 {
    padding-bottom: 17px;
}

.pd-b-18 {
    padding-bottom: 18px;
}

.pd-b-19 {
    padding-bottom: 19px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.pd-b-21 {
    padding-bottom: 21px;
}

.pd-b-22 {
    padding-bottom: 22px;
}

.pd-b-23 {
    padding-bottom: 23px;
}

.pd-b-24 {
    padding-bottom: 24px;
}

.pd-b-25 {
    padding-bottom: 25px;
}

.pd-b-26 {
    padding-bottom: 26px;
}

.pd-b-27 {
    padding-bottom: 27px;
}

.pd-b-28 {
    padding-bottom: 28px;
}

.pd-b-29 {
    padding-bottom: 29px;
}

.pd-b-30 {
    padding-bottom: 30px;
}

.pd-b-31 {
    padding-bottom: 31px;
}

.pd-b-32 {
    padding-bottom: 32px;
}

.pd-b-33 {
    padding-bottom: 33px;
}

.pd-b-34 {
    padding-bottom: 34px;
}

.pd-b-35 {
    padding-bottom: 35px;
}

.pd-b-36 {
    padding-bottom: 36px;
}

/* Margin */
.mg-0 {
    margin: 0;
}

.mg-1 {
    margin: 1px;
}

.mg-2 {
    margin: 2px;
}

.mg-3 {
    margin: 3px;
}

.mg-4 {
    margin: 4px;
}

.mg-5 {
    margin: 5px;
}

.mg-6 {
    margin: 6px;
}

.mg-7 {
    margin: 7px;
}

.mg-8 {
    margin: 8px;
}

.mg-9 {
    margin: 9px;
}

.mg-10 {
    margin: 10px;
}

.mg-11 {
    margin: 11px;
}

.mg-12 {
    margin: 12px;
}

.mg-13 {
    margin: 13px;
}

.mg-14 {
    margin: 14px;
}

.mg-15 {
    margin: 15px;
}

.mg-16 {
    margin: 16px;
}

.mg-17 {
    margin: 17px;
}

.mg-18 {
    margin: 18px;
}

.mg-19 {
    margin: 19px;
}

.mg-20 {
    margin: 20px;
}

.mg-21 {
    margin: 21px;
}

.mg-22 {
    margin: 22px;
}

.mg-23 {
    margin: 23px;
}

.mg-24 {
    margin: 24px;
}

.mg-25 {
    margin: 25px;
}

.mg-26 {
    margin: 26px;
}

.mg-27 {
    margin: 27px;
}

.mg-28 {
    margin: 28px;
}

.mg-29 {
    margin: 29px;
}

.mg-30 {
    margin: 30px;
}

.mg-31 {
    margin: 31px;
}

.mg-32 {
    margin: 32px;
}

.mg-33 {
    margin: 33px;
}

.mg-34 {
    margin: 34px;
}

.mg-35 {
    margin: 35px;
}

.mg-36 {
    margin: 36px;
}

/* Margin Bottom */
.mg-b-0 {
    margin-bottom: 0;
}

.mg-b-1 {
    margin-bottom: 1px;
}

.mg-b-2 {
    margin-bottom: 2px;
}

.mg-b-3 {
    margin-bottom: 3px;
}

.mg-b-4 {
    margin-bottom: 4px;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.mg-b-6 {
    margin-bottom: 6px;
}

.mg-b-7 {
    margin-bottom: 7px;
}

.mg-b-8 {
    margin-bottom: 8px;
}

.mg-b-9 {
    margin-bottom: 9px;
}

.mg-b-10 {
    margin-bottom: 10px;
}

.mg-b-11 {
    margin-bottom: 11px;
}

.mg-b-12 {
    margin-bottom: 12px;
}

.mg-b-13 {
    margin-bottom: 13px;
}

.mg-b-14 {
    margin-bottom: 14px;
}

.mg-b-15 {
    margin-bottom: 15px;
}

.mg-b-16 {
    margin-bottom: 16px;
}

.mg-b-17 {
    margin-bottom: 17px;
}

.mg-b-18 {
    margin-bottom: 18px;
}

.mg-b-19 {
    margin-bottom: 19px;
}

.mg-b-20 {
    margin-bottom: 20px;
}

.mg-b-21 {
    margin-bottom: 21px;
}

.mg-b-22 {
    margin-bottom: 22px;
}

.mg-b-23 {
    margin-bottom: 23px;
}

.mg-b-24 {
    margin-bottom: 24px;
}

.mg-b-25 {
    margin-bottom: 25px;
}

.mg-b-26 {
    margin-bottom: 26px;
}

.mg-b-27 {
    margin-bottom: 27px;
}

.mg-b-28 {
    margin-bottom: 28px;
}

.mg-b-29 {
    margin-bottom: 29px;
}

.mg-b-30 {
    margin-bottom: 30px;
}

.mg-b-31 {
    margin-bottom: 31px;
}

.mg-b-32 {
    margin-bottom: 32px;
}

.mg-b-33 {
    margin-bottom: 33px;
}

.mg-b-34 {
    margin-bottom: 34px;
}

.mg-b-35 {
    margin-bottom: 35px;
}

.mg-b-36 {
    margin-bottom: 36px;
}

.mg-b-42 {
    margin-bottom: 42px;
}

/* Margin Top */
.mg-t-0 {
    margin-top: 0;
}

.mg-t-1 {
    margin-top: 1px;
}

.mg-t-2 {
    margin-top: 2px;
}

.mg-t-3 {
    margin-top: 3px;
}

.mg-t-4 {
    margin-top: 4px;
}

.mg-t-5 {
    margin-top: 5px;
}

.mg-t-6 {
    margin-top: 6px;
}

.mg-t-7 {
    margin-top: 7px;
}

.mg-t-8 {
    margin-top: 8px;
}

.mg-t-9 {
    margin-top: 9px;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-t-11 {
    margin-top: 11px;
}

.mg-t-12 {
    margin-top: 12px;
}

.mg-t-13 {
    margin-top: 13px;
}

.mg-t-14 {
    margin-top: 14px;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-t-16 {
    margin-top: 16px;
}

.mg-t-17 {
    margin-top: 17px;
}

.mg-t-18 {
    margin-top: 18px;
}

.mg-t-19 {
    margin-top: 19px;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-t-21 {
    margin-top: 21px;
}

.mg-t-22 {
    margin-top: 22px;
}

.mg-t-23 {
    margin-top: 23px;
}

.mg-t-24 {
    margin-top: 24px;
}

.mg-t-25 {
    margin-top: 25px;
}

.mg-t-26 {
    margin-top: 26px;
}

.mg-t-27 {
    margin-top: 27px;
}

.mg-t-28 {
    margin-top: 28px;
}

.mg-t-29 {
    margin-top: 29px;
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-t-31 {
    margin-top: 31px;
}

.mg-t-32 {
    margin-top: 32px;
}

.mg-t-33 {
    margin-top: 33px;
}

.mg-t-34 {
    margin-top: 34px;
}

.mg-t-35 {
    margin-top: 35px;
}

.mg-t-36 {
    margin-top: 36px;
}

/* Margin Left */
.mg-l-0 {
    margin-left: 0;
}

.mg-l-1 {
    margin-left: 1px;
}

.mg-l-2 {
    margin-left: 2px;
}

.mg-l-3 {
    margin-left: 3px;
}

.mg-l-4 {
    margin-left: 4px;
}

.mg-l-5 {
    margin-left: 5px;
}

.mg-l-6 {
    margin-left: 6px;
}

.mg-l-7 {
    margin-left: 7px;
}

.mg-l-8 {
    margin-left: 8px;
}

.mg-l-9 {
    margin-left: 9px;
}

.mg-l-10 {
    margin-left: 10px;
}

.mg-l-11 {
    margin-left: 11px;
}

.mg-l-12 {
    margin-left: 12px;
}

.mg-l-13 {
    margin-left: 13px;
}

.mg-l-14 {
    margin-left: 14px;
}

.mg-l-15 {
    margin-left: 15px;
}

.mg-l-16 {
    margin-left: 16px;
}

.mg-l-17 {
    margin-left: 17px;
}

.mg-l-18 {
    margin-left: 18px;
}

.mg-l-19 {
    margin-left: 19px;
}

.mg-l-20 {
    margin-left: 20px;
}

.mg-l-21 {
    margin-left: 21px;
}

.mg-l-22 {
    margin-left: 22px;
}

.mg-l-23 {
    margin-left: 23px;
}

.mg-l-24 {
    margin-left: 24px;
}

.mg-l-25 {
    margin-left: 25px;
}

.mg-l-26 {
    margin-left: 26px;
}

.mg-l-27 {
    margin-left: 27px;
}

.mg-l-28 {
    margin-left: 28px;
}

.mg-l-29 {
    margin-left: 29px;
}

.mg-l-30 {
    margin-left: 30px;
}

.mg-l-31 {
    margin-left: 31px;
}

.mg-l-32 {
    margin-left: 32px;
}

.mg-l-33 {
    margin-left: 33px;
}

.mg-l-34 {
    margin-left: 34px;
}

.mg-l-35 {
    margin-left: 35px;
}

.mg-l-36 {
    margin-left: 36px;
}

/* Margin Right */
.mg-r-0 {
    margin-right: 0;
}

.mg-r-1 {
    margin-right: 1px;
}

.mg-r-2 {
    margin-right: 2px;
}

.mg-r-3 {
    margin-right: 3px;
}

.mg-r-4 {
    margin-right: 4px;
}

.mg-r-5 {
    margin-right: 5px;
}

.mg-r-6 {
    margin-right: 6px;
}

.mg-r-7 {
    margin-right: 7px;
}

.mg-r-8 {
    margin-right: 8px;
}

.mg-r-9 {
    margin-right: 9px;
}

.mg-r-10 {
    margin-right: 10px;
}

.mg-r-11 {
    margin-right: 11px;
}

.mg-r-12 {
    margin-right: 12px;
}

.mg-r-13 {
    margin-right: 13px;
}

.mg-r-14 {
    margin-right: 14px;
}

.mg-r-15 {
    margin-right: 15px;
}

.mg-r-16 {
    margin-right: 16px;
}

.mg-r-17 {
    margin-right: 17px;
}

.mg-r-18 {
    margin-right: 18px;
}

.mg-r-19 {
    margin-right: 19px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-r-21 {
    margin-right: 21px;
}

.mg-r-22 {
    margin-right: 22px;
}

.mg-r-23 {
    margin-right: 23px;
}

.mg-r-24 {
    margin-right: 24px;
}

.mg-r-25 {
    margin-right: 25px;
}

.mg-r-26 {
    margin-right: 26px;
}

.mg-r-27 {
    margin-right: 27px;
}

.mg-r-28 {
    margin-right: 28px;
}

.mg-r-29 {
    margin-right: 29px;
}

.mg-r-30 {
    margin-right: 30px;
}

.mg-r-31 {
    margin-right: 31px;
}

.mg-r-32 {
    margin-right: 32px;
}

.mg-r-33 {
    margin-right: 33px;
}

.mg-r-34 {
    margin-right: 34px;
}

.mg-r-35 {
    margin-right: 35px;
}

.mg-r-36 {
    margin-right: 36px;
}

.d-inline-block {
    display: inline-block;
}
.top_fish_tank {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9999;
}

.top_fish_tank:hover {
    cursor: pointer;
}

.top_fish_tank svg {
    font-size: 16px;
    margin-left: 10px;
}

.MuiButtonBase-root.user-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 50%;
    background-color: var(--White100);
    border: 1px solid var(--Gray200);
}

.module-container {
    width: 100%;
    height: auto;
    background-color: var(--White100);
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 8px;
    min-height: 777px;
}

#account-dropdown .MuiList-root {
    padding: 4px 4px 4px 4px;
    border-radius: 8px;
}

#account-dropdown .MuiPaper-root,
#tank-dropdown .MuiPaper-root {
    border-radius: 8px;
    margin-top: 8px;
}

#account-dropdown .MuiMenuItem-root{
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
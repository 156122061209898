#tank-dropdown li.MuiMenuItem-root{
    display: block;
}

#tank-dropdown p {
    margin: 0;
    padding: 0;
}

#tank-dropdown .MuiList-root{
    padding: 4px 4px 4px 4px;
    border-radius: 8px;
}

#tank-dropdown .MuiButtonBase-root {
    border-radius: 8px;
}

#tank-dropdown .MuiButtonBase-root:not(:last-child) {
    border-bottom: 1px solid var(--Gray100);
}
.reset-password-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-password-modal .modal-content {
    width: 391px;
    max-width: 391px;
    background: #fff;
    border-radius: 24px;
    margin-top: 117px;
}

.reset-password-modal .modal-body {
    padding: 24px;
}

.reset-password-modal .modal-footer {
    padding: 12px;
    border-top: 1px solid var(--Gray200);
    background-color: var(--Gray100);
    border-radius: 0 0 24px 24px;
}

.reset-password-modal .MuiFormControl-root:not(:last-child){
    margin-bottom: 16px;
}

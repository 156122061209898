.sidebar.card.card-container {
    min-height: 777px;
    width: 100%;
    background-color: var(--Gray200);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.widget-container {
    padding: 16px;
    background-color: var(--Gray100);
    border-radius: 24px;
    margin-bottom: 8px;
    display: block;
    text-decoration: none;
    color: #000;
}

.widget-container:hover {
    cursor: pointer;
}

.widget-container p,
.widget-container a{
 text-decoration: none;
 color: #000;
}

.main-widget-title-container {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--Black100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.sidebar .icon-container {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.icon-container-green {    
    background-color: var(--Green200);
}

.icon-container-light-green {    
    background-color: var(--Green600);
}


.icon-container-yellow {    
    background-color: var(--Yellow200);
}

.icon-container-purple {    
    background-color: var(--Purple300);
}

.icon-container-pink {    
    background-color: var(--Pink100);
}

.icon-container-blue {    
    background-color: var(--Blue200);
}

.icon-container-red {    
    background-color: var(--Red100);
}

.icon-container-orange {    
    background-color: rgba(var(--Orange100_RGB), 0.5);
}

.icon-container-light-blue {    
    background-color: var(--Blue100);
}

.icon-container-gray {    
    background-color: var(--Gray200);
}

.cooler-icon-container svg{
    height: 28px;
}

.rtc-icon-container svg{
    height: 24px;
}

.main-widget-title {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.main-width-chevron {
    font-size: 16px;
}

.widget-details p{
    text-align: right;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
    margin-bottom: 8px;
}

span.text-muted,
p.text-muted {
    font-weight: 400;
    color: var(--Gray600)!important;
}

span.text-muted-l,
p.text-muted-l {
    font-weight: 400;
    color: var(--Gray500)!important;
}

.text-muted-xl {
    font-weight: 400;
    color: var(--Gray300)!important;

}

.card-footer-muted p{
    text-align: center;
}

.light-widget-title-container {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--Black100);
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.text-ph {
    font-size: 18px;
}

.socket-status-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 8px;
}

.socket-status-column {
    margin-left: 8px;
}

.socket-status-column span{
    display: flex;
    justify-content: center;
}

.text-on-off {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--Black100);
    margin-bottom: 4px;
}

.socket-off span {
    opacity: 0.3;
}

.row.gutter-x-8 {
    --bs-gutter-x: 8px;
}

.feederDetail-ago,
.tdsDetails-ppm {
    font-size: 16px;
    font-weight: 500;
}

.widget-details .sidebar-detail-text {
    font-size: 18px;
}

.water-drop-icon-container svg {
    height: 24px;
}

.widget-container[data-is_active='true']{
    background-color: var(--White100);
}
.rtc-summary-title-container {
    text-align: center;
}

.rtc-summary-title-container time, 
.rtc-summary-title-container span,
.rtc-summary-title-container p{
    font-size: 2rem;
}

.rtc-summary-title-container time {
    margin-bottom: 0;
}

.rtc-summary-title-container p {
    margin-top: 0;
    margin-bottom: 0;
}

.rtc-summary-title-container .ip-address {
    font-size: 1.3rem;
    color: var(--Gray700);
    margin-top: 1rem;
}
.login-links {
    color: var(--Gray700);
    text-decoration: underline;
    font-size: 14px;
    line-height: 16.94px;
}

.btn-social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 12px;
    border-radius: 24px;
    margin-bottom: 16px;
    gap: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.login-subtitle {
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: var(--Gray700);
}
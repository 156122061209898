.remove-module-modal .modal-dialog{
    max-width: 391px;
}

.remove-module-modal .modal-dialog .modal-content {
    border-radius: 24px;
}

.remove-module-modal button:not(.btn-gray-close) {
    padding: 10px 16px;
    margin-top: 24px;
}

.remove-module-modal .modal-body {
    padding: 24px;
}

.remove-module-modal .title-modal {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}

.remove-module-modal .remove-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
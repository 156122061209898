.auth-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    min-height: 100vh;
    background-color: var(--Gray200);
    /* justify-content: space-between; */
    gap: 50px;
}

.auth-main > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.auth-main > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-container {
    margin-top: 50px;
    padding: 56px 32px 32px 32px;
    border-radius: 24px;
    width: 407px;
    max-width: 407px;
    background-color: var(--White100);
    text-align: center;
}

.auth-main .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: left;
}

.auth-main .text-input {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
}

.auth-main .btn-auth {
    margin-top: 8px;
    width: 100%;
    padding: 16px 12px;
    border-radius: 24px;
    margin-bottom: 24px;
}

.auth-main .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 14.52px;
    color: var(--Gray600);
    margin-bottom: 24px;
}

.auth-main .separator::before, 
.auth-main .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--Gray200);
}

.separator span {
    margin: 0 8px; 
}

.auth-main .separator:not(:empty)::before {
    margin-right: .25em;
}

.auth-main .separator:not(:empty)::after {
    margin-left: .25em;
}
:root {
    --Gray100: #F7F7FB;
    --Gray200: #E7E7F1;
    --Gray250: #DBDBE6;
    --Gray300: #CFCFDA;
    --Gray400: #A6A6B1;
    --Gray500: #8F8F97;
    --Gray600: #67676F;
    --Gray700: #35353B;
    --Gray800: #000004;

    --Blue100: #BCD7FB;
    --Blue200: #7BB4FF;
    --Blue300: #2F86FA;
    --Blue350: #1373f2;
    --Blue400: #0063E7;
    --Blue500: #004196;
    --Blue600: #041E41;
    --Blue700: #A3F5F5;
    --Blue800: #A3CCF5;
    --Blue900: #0097B9;
    --Blue1000: #9AD6E3;

    --Green100: #DEF3D1;
    --Green200: #AAE486;
    --Green300: #5CB028;
    --Green400: #2B6706;
    --Green500: #DAF5A3;
    --Green600: #A3F5A3;

    --Yellow100: #F2EDD0;
    --Yellow200: #E5D687;
    --Yellow300: #D4B619;
    --Yellow400: #665706;
    --Yellow500: #F5E7A3;
    --Yellow600: #E5D687;

    --Red100: #F2D0D2;
    --Red200: #F2AEB3;
    --Red300: #D3121D;
    --Red350: #de101b;
    --Red400: #66060C;

    --White100: #FFFFFF;
    
    --Black100: #000000;
    --Black200: #000004;

    --Orange100: #F5CCA3;
    --Orange100_RGB: 245, 204, 163;

    --Purple100: #A3A3F5;
    --Purple200: #DDA3F5;
    --Purple300: #D8BAFF;

    --Pink100: #F5A3CA;

    --CustomWhite200: #E5E5E5;
}